import React, { useEffect, useState } from "react";
import FeedbackPopup from "../../common/components/popup/feedbackPopup";
import { tireComponent } from "../../constants";
import Utils from "../../utility";

const initialListData = [
  {
    componentName: "VIN",
    claimData: "",
    systemData: "",
    result: "",
    feedback: ""
  },
  {
    componentName: "Commercial Usage Allowed",
    claimData: "",
    systemData: "",
    result: "",
    feedback: ""
  },
  {
    componentName: "Customer Last Name",
    claimData: "",
    systemData: "",
    result: "",
    feedback: ""
  },
  {
    componentName: "Customer Address",
    claimData: "",
    systemData: "",
    result: "",
    feedback: ""
  },
  {
    componentName: "Odometer Reading",
    claimData: "",
    systemData: "",
    result: "",
    feedback: ""
  },
  {
    componentName: "Is the difference is <=50 ",
    claimData: "",
    systemData: "",
    result: "",
    feedback: ""
  }
];
const ComparisonList = ({
  imagesData,
  setImagePopup,
  openReasonPopup,
  setElementData,
  elementData,
  handleFeedback,
  setOpenReasonPopup,
  data,
  handleUpdateFeedback,
  setQuestions,
  questions,
  setCheckData,
  checkData,
  setComponentType,
  componentType,
  localFeedbackData
}) => {
  const [hoverId, setHoverId] = useState(null);
  const [comparisonData, setComparisonData] = useState([]);
  const [componentData, setComponentData] = useState(initialListData);

  let uniqueIdCounter = 1;
  const dataMapping = {
    vin: 0,
    commercialUsageAllowed: 1,
    customerLastName: 2,
    customerAddress: 3,
    odoMeterReading: 4,
    odoMeterDifference: 5
  };

  const mapVehicleDataToTbody = (vehicleData, customerData) => {
    const combinedData = { ...vehicleData, ...customerData };

    return initialListData.map((item, index) => {
      const dataKey = Object.keys(dataMapping).find(
        (key) => dataMapping[key] === index
      );
      const vehicleItem = combinedData[dataKey] || {};
      const matchingFeedback = localFeedbackData?.elementsFeedback?.find(
        (feedback) =>
          feedback.elementName.toLowerCase() ===
          item.componentName.toLowerCase()
      );

      return {
        _id: uniqueIdCounter++,
        componentName: item.componentName,
        claimData: vehicleItem.claimData || item.claimData || "-",
        systemResults: vehicleItem.systemData || item.systemData || "-",
        result: vehicleItem.result || item.result,
        feedback: matchingFeedback ? matchingFeedback : item.feedback
      };
    });
  };

  const mapComponentsToTableData = (components) => {
    return components?.map((component) => {
      const claimDataApprove = component?.sgComponentStatus;

      const isSystemResultApproved =
        component?.componentTypeLocation?.componentStatus;

      const thead = [
        {
          name: component.componentName
        },
        {
          name: claimDataApprove
        },
        {
          name: isSystemResultApproved,
          componentName: component.componentName,
          componentId: component?._id,
          moreSpecification:
            component?.componentTypeLocation?.componentTypeData
              ?.mandatoryChecks,
          isMoreDetailsAvaialble: true,
          reason: component?.componentTypeLocation?.componentDescription,
          feedback: localFeedbackData?.checks
        },
        {
          name:
            isSystemResultApproved === claimDataApprove
              ? "Matched"
              : "Mismatched",
          status: true
        }
      ];

      const tbody = [];
      const damageDescription =
        component.componentTypeLocation?.componentTypeData
          ?.componentDamageDescription;

      if (damageDescription) {
        Object.keys(damageDescription).forEach((damageType) => {
          const damage = damageDescription[damageType];
          if (damage.isDamageIdentified) {
            setQuestions((prev) => ({
              ...prev,
              [component.componentName]: damage?.componentAdditionalQuestions
            }));

            const matchingFeedback = localFeedbackData?.elementsFeedback?.find(
              (feedback) =>
                feedback.elementName ===
                "Tire Damage Description" + " " + component.componentName
            );
            tbody.push({
              _id: uniqueIdCounter++,
              element: `${componentType === "TIRE" ? "Tire" : "Wheel"} Damage Description`,
              componentName: component.componentName,
              claimData:
                component.tireDamageDescription ||
                component?.wheelDamageDescription ||
                "-",
              systemResults: `${component.tireDamageDescription || component?.wheelDamageDescription || "-"} ${
                damage?.isDamageIdentified === "Yes"
                  ? "identified"
                  : "not identified"
              }`,
              result:
                damage?.isDamageIdentified === "Yes" ? "Matched" : "Mismatched",
              feedback: matchingFeedback ? matchingFeedback : ""
            });
          }
        });
      }
      const matchingFeedback = localFeedbackData?.elementsFeedback?.find(
        (feedback) =>
          feedback.elementName ===
          "Corrective Action" + " " + component.componentName
      );

      tbody.push({
        _id: uniqueIdCounter++,
        element: "Corrective Action",
        componentName: component.componentName,
        claimData: component.componentTypeLocation?.claimDataAction || "-",
        systemResults: component.componentTypeLocation?.componentAction || "-",
        result:
          component.componentTypeLocation?.claimDataAction ===
          component.componentTypeLocation?.componentAction
            ? "Matched"
            : "Mismatched",
        feedback: matchingFeedback ? matchingFeedback : ""
      });
      tbody.push({
        mandatoryChecks:
          component?.componentTypeLocation?.componentTypeData?.mandatoryChecks,
        componentName: component.componentName,
        isMoreDetailsAvaialble: true,
        reason: component?.componentTypeLocation?.componentDescription,
        systemResult: isSystemResultApproved,
        feedback: localFeedbackData?.checks,
        componentId: component?._id
      });

      return { thead, tbody };
    });
  };

  useEffect(() => {
    let mappedData = [];

    if (data?.vehicleData) {
      mappedData = [
        ...mappedData,
        ...mapVehicleDataToTbody(
          data?.vehicleData,
          data.customer,
          data.feedback || []
        )
      ];
    }

    if (mappedData?.length > 0) setComponentData(mappedData);
    //eslint-disable-next-line
  }, [data, localFeedbackData]);

  useEffect(() => {
    let tableData;
    const componentTireData = data?.components?.filter(
      (item) => item.componentType?.toLowerCase() === "tire"
    );

    const componentWheelData = data?.components?.filter(
      (item) => item.componentType?.toLowerCase() === "wheel"
    );
    if (data?.components?.length > 0 && data?.components) {
      if (componentType === "TIRE") {
        tableData = mapComponentsToTableData(
          componentTireData,
          data.feedback || []
        );
      } else {
        tableData = mapComponentsToTableData(
          componentWheelData,
          data.feedback || []
        );
      }
    }

    setComparisonData(tableData);
  }, [data, componentType, localFeedbackData]);

  const claimData = imagesData?.filter((item) => item.type !== "damage");

  return (
    <div>
      <div>
        <h1 className="font-InterBold text-ft20-28 text-black-10">
          Comparison
        </h1>
      </div>
      <div className="mt-4 w-full ">
        <table className="w-full rounded-[10px] bg-white">
          <thead>
            <tr className="font-InterBold text-ft15-18 text-black-10">
              <td className="py-3.5 pl-3.5"></td>
              <td className="border-l border-grey-60 py-3.5 pl-3.5">
                Claim Data
              </td>
              <td className="border-l border-grey-60 py-3.5 pl-3.5">
                System Results
              </td>
              <td className="border-l border-grey-60 py-3.5 pl-3.5">Result</td>
            </tr>
          </thead>

          <ComparisonBody
            openReasonPopup={openReasonPopup}
            setImagePopup={setImagePopup}
            setOpenReasonPopup={setOpenReasonPopup}
            data={componentData}
            hoverId={hoverId}
            imagesData={claimData}
            setHoverId={setHoverId}
            setElementData={setElementData}
            elementData={elementData}
            handleFeedback={handleFeedback}
            handleUpdateFeedback={handleUpdateFeedback}
            feedback={localFeedbackData}
          />
        </table>
        <div className=" my-3 flex h-10 items-center gap-5">
          {data?.components?.find(
            (item) => item.componentType?.toLowerCase() === "tire"
          ) && (
            <span
              onClick={() => setComponentType("TIRE")}
              className={` ${componentType === "TIRE" ? "border border-green-10 bg-green-10" : "border border-grey-10 bg-white"} w-20 cursor-pointer rounded-lg bg-opacity-10 py-2 text-center font-InterMedium text-ft16-24 text-black-0`}
            >
              Tire
            </span>
          )}
          {data?.components?.find(
            (item) => item.componentType?.toLowerCase() === "wheel"
          ) && (
            <span
              onClick={() => setComponentType("WHEEL")}
              className={` ${componentType === "WHEEL" ? "border border-green-10 bg-green-10" : "border border-grey-10 bg-white"} w-20 cursor-pointer rounded-lg bg-opacity-10 py-2 text-center font-InterMedium text-ft16-24 text-black-0`}
            >
              Wheel
            </span>
          )}
        </div>
        {comparisonData?.map((item, idx) => (
          <div key={idx}>
            <ComponentTable
              openReasonPopup={openReasonPopup}
              setImagePopup={setImagePopup}
              setOpenReasonPopup={setOpenReasonPopup}
              tbody={item.tbody}
              thead={item.thead}
              hoverId={hoverId}
              imagesData={imagesData}
              setHoverId={setHoverId}
              setElementData={setElementData}
              elementData={elementData}
              handleFeedback={handleFeedback}
              handleUpdateFeedback={handleUpdateFeedback}
              feedback={localFeedbackData}
              questions={questions}
              checkData={checkData}
              setCheckData={setCheckData}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComparisonList;

const ComparisonBody = ({
  data,
  setImagePopup,
  setOpenReasonPopup,
  openReasonPopup,
  setHoverId,
  hoverId,
  imagesData,
  setElementData,
  elementData,
  handleFeedback,
  handleUpdateFeedback,
  feedback
}) => {
  return (
    <tbody>
      {data?.map((item, idx) => (
        <tr key={idx} className="border-t border-grey-30">
          <td className="w-25per py-3.5 pl-3.5 font-InterRegular text-ft10-18.15 text-grey-50">
            <div>{item.componentName}</div>
          </td>
          <td className="w-30per border-l border-grey-60 pl-3.5 font-InterRegular text-ft10-18.15 text-black-10">
            <div>
              {item.claimData?.toLowerCase() === "n"
                ? "No"
                : item?.claimData?.toLowerCase() === "y"
                  ? "Yes"
                  : item.claimData}
            </div>
          </td>
          <td
            onMouseEnter={() => {
              if (!openReasonPopup) setHoverId(item._id);
            }}
            onMouseLeave={() => {
              if (!openReasonPopup) setHoverId(null);
            }}
            className="w-30per border-l border-grey-60 px-3.5 font-InterRegular text-ft10-18.15 text-black-10"
          >
            <div className="flex items-center justify-between">
              <span>
                {item.systemResults?.toLowerCase() === "n"
                  ? "No"
                  : item.systemResults?.toLowerCase() === "y"
                    ? "Yes"
                    : item.systemResults}
              </span>
              {item?.feedback && (
                <div className="relative flex gap-3">
                  {hoverId === item._id && item?.feedback && (
                    <img
                      onClick={() =>
                        setImagePopup((prev) => ({
                          ...prev,
                          popup: true,
                          allImages: imagesData,
                          idx: 0
                        }))
                      }
                      src="/images/image-logo.svg"
                      alt=""
                      className="cursor-pointer"
                    />
                  )}
                  <img
                    onClick={() => {
                      setOpenReasonPopup(item._id);
                      setElementData((prev) => ({
                        ...prev,
                        elementName: item?.feedback?.elementName,
                        systemResult: item?.feedback?.systemResults,
                        claimData: item?.feedback?.claimData,
                        feedback: item.feedback.feedback,
                        comment: item?.feedback?.comment
                      }));
                    }}
                    src="/images/red-thumbs-down.svg"
                    alt=""
                    className="cursor-pointer"
                  />
                  {openReasonPopup === item._id && (
                    <FeedbackPopup
                      setState={() => setOpenReasonPopup(null)}
                      elementData={elementData}
                      setElementData={setElementData}
                      handleFeedback={handleFeedback}
                      handleUpdateFeedback={handleUpdateFeedback}
                      edit={true}
                    />
                  )}
                </div>
              )}
              {hoverId === item._id && !item?.feedback && (
                <div className="relative flex gap-3">
                  <img
                    onClick={() =>
                      setImagePopup((prev) => ({
                        ...prev,
                        popup: true,
                        allImages: imagesData,
                        idx: 0
                      }))
                    }
                    src="/images/image-logo.svg"
                    alt=""
                    className="cursor-pointer"
                  />
                  <img
                    onClick={() => {
                      setOpenReasonPopup(item._id);
                      setElementData((prev) => ({
                        ...prev,
                        elementName: item?.componentName,
                        systemResult: item.systemResults,
                        claimData: item.claimData
                      }));
                    }}
                    src="/images/dislike-black.svg"
                    alt=""
                    className="cursor-pointer"
                  />
                  {openReasonPopup === item._id && (
                    <FeedbackPopup
                      setState={() => setOpenReasonPopup(null)}
                      elementData={elementData}
                      setElementData={setElementData}
                      handleFeedback={handleFeedback}
                      handleUpdateFeedback={handleUpdateFeedback}
                      callUpdate={feedback ? true : false}
                    />
                  )}
                </div>
              )}
            </div>
          </td>
          <td className="w-15per border-l border-grey-60 p-3.5">
            <div
              className={`${
                item.result === "Mismatched" ? " text-red-10" : " text-green-10"
              } rounded-full font-InterMedium text-ft15-18`}
            >
              {item.result || "-"}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const ComponentTable = ({
  thead,
  tbody,
  setImagePopup,
  setOpenReasonPopup,
  openReasonPopup,
  setHoverId,
  hoverId,
  imagesData,
  setElementData,
  elementData,
  handleFeedback,
  handleUpdateFeedback,
  feedback,
  questions,
  checkData,
  setCheckData
}) => {
  const [checks, setChecks] = useState(true);

  const leftRearTire = imagesData?.filter(
    (item) => item.componentName === tireComponent.leftRearTire
  );
  const leftFrontTire = imagesData?.filter(
    (item) => item.componentName === tireComponent.leftFrontTire
  );
  const rightRearTire = imagesData?.filter(
    (item) => item.componentName === tireComponent.rightRearTire
  );
  const rightFrontTire = imagesData?.filter(
    (item) => item.componentName === tireComponent.rightFrontTire
  );

  const leftRearWheel = imagesData?.filter(
    (item) => item.componentName === tireComponent.leftRearWheel
  );
  const leftFrontWheel = imagesData?.filter(
    (item) => item.componentName === tireComponent.leftFrontWheel
  );
  const rightRearWheel = imagesData?.filter(
    (item) => item.componentName === tireComponent.rightRearWheel
  );
  const rightFrontWheel = imagesData?.filter(
    (item) => item.componentName === tireComponent.rightFrontWheel
  );

  const handleImageType = (name) => {
    let allImages = [];
    switch (name) {
      case tireComponent.leftRearTire:
        allImages = leftRearTire;
        break;
      case tireComponent.rightRearTire:
        allImages = rightRearTire;
        break;
      case tireComponent.leftFrontTire:
        allImages = leftFrontTire;
        break;
      case tireComponent.rightFrontTire:
        allImages = rightFrontTire;
        break;
      case tireComponent.leftRearWheel:
        allImages = leftRearWheel;
        break;
      case tireComponent.rightRearWheel:
        allImages = rightRearWheel;
        break;
      case tireComponent.leftFrontWheel:
        allImages = leftFrontWheel;
        break;
      case tireComponent.rightFrontWheel:
        allImages = rightFrontWheel;
        break;
      default:
        allImages = [];
    }
    return allImages;
  };

  const mandatoryChecksStatus = (checkValues) => {
    const sameTireCheck = checkValues?.find(
      (check) =>
        check?.checkName?.toLowerCase() === "sametire" &&
        check?.checkValue?.toLowerCase() === "yes"
    );
    const otherChecksAreNo = checkValues?.every((check) => {
      if (check?.checkName?.toLowerCase() !== "sametire") {
        return check?.checkValue?.toLowerCase() === "no";
      }
      return true;
    });
    if (sameTireCheck && otherChecksAreNo) {
      return "Approved";
    } else {
      return "Denied";
    }
  };

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr className="font-InterBold text-ft15-18 text-black-10">
            {thead.map((item, idx) => {
              return (
                <td
                  key={idx}
                  className={` bg-darkGrey-30 py-3.5  pl-3.5 ${idx != 0 && "border-l border-grey-60"}`}
                >
                  <div className="flex items-center gap-1">
                    <span
                      className={`whitespace-nowrap ${
                        item.name === "Mismatched" && item.status
                          ? " text-red-10"
                          : item.status && " font-InterMedium text-green-10"
                      }`}
                    >
                      {item.name}
                    </span>

                    {item.isMoreDetailsAvaialble && (
                      <div className="relative w-full">
                        <img
                          src={
                            item.isMoreDetailsAvaialble &&
                            item.name === "Approved"
                              ? "/images/green-check.svg"
                              : "/images/red-checkMark.svg"
                          }
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tbody?.map((item, idx) => {
            const questionValues = questions[item?.componentName] || [];
            const filterQuestionText = questionValues?.filter(
              (item) => item.questionText !== ""
            );
            const mandatoryCheck = mandatoryChecksStatus(item.mandatoryChecks);
            return (
              <>
                {!item?.isMoreDetailsAvaialble && (
                  <tr key={idx} className="border-t border-grey-30 bg-white">
                    <td className="w-25per py-3.5 pl-3.5 font-InterRegular text-ft10-18.15 text-grey-50">
                      <div>{item.element}</div>
                    </td>
                    <td className="w-30per border-l border-grey-60 pl-3.5 font-InterRegular text-ft10-18.15 text-black-10">
                      <div>
                        {item.claimData?.toLowerCase() === "n"
                          ? "No"
                          : item?.claimData?.toLowerCase() === "y"
                            ? "Yes"
                            : item.claimData}
                      </div>
                    </td>
                    <td
                      onMouseEnter={() => {
                        if (!openReasonPopup) setHoverId(item._id);
                      }}
                      onMouseLeave={() => {
                        if (!openReasonPopup) setHoverId(null);
                      }}
                      className="w-30per border-l border-grey-60 px-3.5 font-InterRegular text-ft10-18.15 text-black-10"
                    >
                      <div className="flex items-center justify-between">
                        <span>
                          {item.systemResults?.toLowerCase() === "n"
                            ? "No"
                            : item.systemResults?.toLowerCase() === "y"
                              ? "Yes"
                              : item.systemResults}
                        </span>
                        {item.feedback && (
                          <div className="relative flex gap-3">
                            {hoverId === item._id && item?.feedback && (
                              <img
                                onClick={() =>
                                  setImagePopup((prev) => ({
                                    ...prev,
                                    popup: true,
                                    allImages: handleImageType(
                                      item.componentName
                                    ),
                                    idx: 0
                                  }))
                                }
                                src="/images/image-logo.svg"
                                alt=""
                                className="cursor-pointer"
                              />
                            )}
                            <img
                              onClick={() => {
                                setOpenReasonPopup(item._id);
                                setElementData((prev) => ({
                                  ...prev,
                                  elementName: item?.feedback?.elementName,
                                  systemResult: item?.feedback?.systemResult,
                                  claimData: item?.feedback?.claimData,
                                  feedback: item.feedback.feedback,
                                  comment: item?.feedback?.comment
                                }));
                              }}
                              src="/images/red-thumbs-down.svg"
                              alt=""
                              className="cursor-pointer"
                            />
                            {openReasonPopup === item._id && (
                              <FeedbackPopup
                                setState={() => setOpenReasonPopup(null)}
                                elementData={elementData}
                                setElementData={setElementData}
                                handleFeedback={handleFeedback}
                                handleUpdateFeedback={handleUpdateFeedback}
                                edit={true}
                              />
                            )}
                          </div>
                        )}
                        {hoverId === item._id && !item?.feedback && (
                          <div className="relative flex gap-3">
                            <img
                              onClick={() =>
                                setImagePopup((prev) => ({
                                  ...prev,
                                  popup: true,
                                  allImages: handleImageType(
                                    item.componentName
                                  ),
                                  idx: 0
                                }))
                              }
                              src="/images/image-logo.svg"
                              alt=""
                              className="cursor-pointer"
                            />
                            <img
                              onClick={() => {
                                setOpenReasonPopup(item._id);
                                setElementData((prev) => ({
                                  ...prev,
                                  elementName:
                                    item?.element + " " + item?.componentName,
                                  systemResult: item.systemResults,
                                  claimData: item.claimData
                                }));
                              }}
                              src="/images/dislike-black.svg"
                              alt=""
                              className="cursor-pointer"
                            />
                            {openReasonPopup === item._id && (
                              <FeedbackPopup
                                setState={() => setOpenReasonPopup(null)}
                                elementData={elementData}
                                setElementData={setElementData}
                                handleFeedback={handleFeedback}
                                handleUpdateFeedback={handleUpdateFeedback}
                                callUpdate={feedback ? true : false}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="w-15per border-l border-grey-60 p-3.5">
                      <div
                        className={`${
                          item.result === "Mismatched"
                            ? " text-red-10"
                            : " text-green-10"
                        } rounded-full font-InterMedium text-ft15-18`}
                      >
                        {item.result}
                      </div>
                    </td>
                  </tr>
                )}
                {item.isMoreDetailsAvaialble && (
                  <tr className="border-t border-grey-60 bg-white">
                    <td colSpan="4">
                      <div className=" flex w-full gap-3 px-4 py-2">
                        <div
                          onClick={() => setChecks(true)}
                          className={`flex h-8 w-max cursor-pointer items-center justify-center rounded-md border p-2 text-ft14-17 ${checks ? "border-black-0 text-black-10" : "border-grey-30 text-black-20"}`}
                        >
                          <span className=" font-InterMedium">Checks</span>
                        </div>
                        <div
                          onClick={() => setChecks(false)}
                          className={`flex h-8 w-max cursor-pointer items-center justify-center rounded-md border p-2 text-ft14-17 ${!checks ? "border-black-0 text-black-10" : "border-grey-30 text-black-20"}`}
                        >
                          <span className=" font-InterMedium">Description</span>
                        </div>
                      </div>
                      {checks ? (
                        <div className="flex w-full flex-col">
                          <div className="w-full px-4 py-2">
                            <div className="flex w-full justify-between">
                              <span className="font-InterRegular text-ft14-17  text-black-20">
                                Mandatory Checks
                              </span>
                              <span
                                className={`font-InterRegular text-ft14-17 ${mandatoryCheck === "Approved" ? "text-green-10" : "text-red-10"} `}
                              >
                                {mandatoryCheck}
                              </span>
                            </div>

                            <div className="flex flex-col gap-3 rounded-md bg-grey-110 p-4">
                              {item.mandatoryChecks?.map((itm, idx) => {
                                const feedbackPresent = item?.feedback?.find(
                                  (value) =>
                                    value.checkId === itm?._id ||
                                    (value.checkName === itm?.checkName &&
                                      value.componentName ===
                                        itm?.componentName)
                                );

                                return (
                                  <div
                                    key={idx}
                                    className="flex justify-between"
                                  >
                                    <span className="font-InterRegular text-ft14-17 text-black-10">
                                      {itm.checkName
                                        .replace(/([A-Z])/g, " $1")
                                        .trim()
                                        .replace(/^./, (str) =>
                                          str.toUpperCase()
                                        )}
                                    </span>
                                    <div className="flex gap-2">
                                      <span className="font-InterMedium text-ft14-17 text-black-10">
                                        {itm?.checkValue === "y"
                                          ? "Yes"
                                          : itm?.checkValue === "n"
                                            ? "No"
                                            : Utils.formatText(itm.checkValue)}
                                      </span>
                                      <div className="relative cursor-pointer">
                                        {feedbackPresent ? (
                                          <img
                                            onClick={() => {
                                              setOpenReasonPopup(itm?._id);
                                              setCheckData((prev) => ({
                                                ...prev,
                                                checkId: itm?._id,
                                                checkName: itm?.checkName,
                                                feedback:
                                                  feedbackPresent?.feedback,
                                                comment:
                                                  feedbackPresent?.comment,
                                                componentName:
                                                  item.componentName,
                                                componentId: item?.componentId,
                                                systemResult: itm?.checkValue
                                              }));
                                            }}
                                            src="/images/red-thumbs-down.svg"
                                            alt=""
                                            className="cursor-pointer"
                                          />
                                        ) : (
                                          <img
                                            onClick={() => {
                                              setOpenReasonPopup(itm?._id);
                                              setCheckData((prev) => ({
                                                ...prev,
                                                checkId: itm?._id,
                                                checkName: itm?.checkName,
                                                feedback:
                                                  feedbackPresent?.feedback,
                                                comment:
                                                  feedbackPresent?.comment,
                                                componentName:
                                                  item?.componentName,
                                                componentId: item?.componentId,
                                                systemResult: itm?.checkValue
                                              }));
                                            }}
                                            src="/images/dislike-black.svg"
                                            alt=""
                                            className="cursor-pointer"
                                          />
                                        )}
                                        {itm?._id === openReasonPopup && (
                                          <FeedbackPopup
                                            align="right-0"
                                            elementData={checkData}
                                            setElementData={setCheckData}
                                            handleFeedback={handleFeedback}
                                            setState={() =>
                                              setOpenReasonPopup(null)
                                            }
                                            handleUpdateFeedback={
                                              handleUpdateFeedback
                                            }
                                            edit={
                                              feedbackPresent ? true : false
                                            }
                                            callUpdate={
                                              item.feedback ? true : false
                                            }
                                            clearData={() =>
                                              setCheckData((prev) => ({
                                                ...prev,
                                                componentName: "",
                                                componentId: "",
                                                checkName: "",
                                                feedback: "",
                                                comment: "",
                                                checkId: "",
                                                systemResult: ""
                                              }))
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          {filterQuestionText?.length > 0 && (
                            <div className="w-full  px-4 py-2">
                              <div className="flex w-full justify-between">
                                <span className="font-InterRegular text-ft14-17 text-black-20">
                                  Additional Questions
                                </span>
                                <span
                                  className={`font-InterRegular text-ft14-17 ${item?.systemResult === "Approved" ? "text-green-10" : "text-red-10"} `}
                                >
                                  {item?.systemResult}
                                </span>
                              </div>

                              <div className="flex flex-col gap-3 rounded-md bg-grey-110 p-4">
                                {filterQuestionText?.map((itm, idx) => {
                                  const feedbackPresent = item?.feedback?.find(
                                    (value) =>
                                      value.checkId === itm?._id ||
                                      (value.checkName === itm?.checkName &&
                                        value.componentName ===
                                          itm?.componentName)
                                  );
                                  return (
                                    <div
                                      key={idx}
                                      className="flex justify-between"
                                    >
                                      <span className="w-85per font-InterRegular text-ft14-17 text-black-10">
                                        {itm.questionText}
                                      </span>
                                      <div className="flex gap-2">
                                        <span className="font-InterMedium text-ft14-17 text-black-10">
                                          {itm?.answer === "y"
                                            ? "Yes"
                                            : itm?.answer === "n"
                                              ? "No"
                                              : Utils.formatText(itm.answer)}
                                        </span>
                                        <div className="relative cursor-pointer">
                                          {feedbackPresent ? (
                                            <img
                                              onClick={() => {
                                                setOpenReasonPopup(itm?._id);
                                                setCheckData((prev) => ({
                                                  ...prev,
                                                  checkId: itm?._id,
                                                  checkName: itm?.questionText,
                                                  feedback:
                                                    feedbackPresent?.feedback,
                                                  comment:
                                                    feedbackPresent?.comment,
                                                  componentName:
                                                    item.componentName,
                                                  componentId:
                                                    item?.componentId,
                                                  systemResult: itm?.checkValue
                                                }));
                                              }}
                                              src="/images/red-thumbs-down.svg"
                                              alt=""
                                              className="cursor-pointer"
                                            />
                                          ) : (
                                            <img
                                              onClick={() => {
                                                setOpenReasonPopup(itm?._id);
                                                setCheckData((prev) => ({
                                                  ...prev,
                                                  checkId: itm?._id,
                                                  checkName: itm?.questionText,
                                                  feedback:
                                                    feedbackPresent?.feedback,
                                                  comment:
                                                    feedbackPresent?.comment,
                                                  componentName:
                                                    item?.componentName,
                                                  componentId:
                                                    item?.componentId,
                                                  systemResult: itm?.checkValue
                                                }));
                                              }}
                                              src="/images/dislike-black.svg"
                                              alt=""
                                              className="cursor-pointer"
                                            />
                                          )}
                                          {itm?._id === openReasonPopup && (
                                            <FeedbackPopup
                                              align="right-0"
                                              elementData={checkData}
                                              setElementData={setCheckData}
                                              handleFeedback={handleFeedback}
                                              setState={() =>
                                                setOpenReasonPopup(null)
                                              }
                                              handleUpdateFeedback={
                                                handleUpdateFeedback
                                              }
                                              edit={
                                                feedbackPresent ? true : false
                                              }
                                              callUpdate={
                                                item.feedback ? true : false
                                              }
                                              clearData={() =>
                                                setCheckData((prev) => ({
                                                  ...prev,
                                                  componentName: "",
                                                  componentId: "",
                                                  checkName: "",
                                                  feedback: "",
                                                  comment: "",
                                                  checkId: "",
                                                  systemResult: ""
                                                }))
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="mx-4 my-2 h-full rounded-md bg-grey-110 p-4 font-InterRegular text-ft14-17  text-black-10">
                          <span>{item.reason}</span>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
