import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ClaimDetails from "./claimDetails";
import ImageZoom from "./imageZoom";
import RightPanel from "./rightPanel";
import Utils from "../../utility";
import {
  getClaimDetails,
  getImages,
  postClaimFeedback,
  updateFeedback
} from "../../services/zbrainConnectorService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ClaimDetailsComponent = () => {
  const navigate = useNavigate();
  const [openImage, setImagePopup] = useState({
    popup: false,
    allImages: [],
    idx: null
  });
  const [openReasonPopup, setOpenReasonPopup] = useState(false);
  const [componentType, setComponentType] = useState("TIRE");
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const [feedbackData, setFeedbackData] = useState({});
  const [feedbackId, setFeedbackId] = useState(null);

  const [elementData, setElementData] = useState({
    elementName: "",
    feedback: "",
    comment: "",
    claimData: "",
    systemResult: ""
  });
  const [checkData, setCheckData] = useState({
    componentName: "",
    componentId: "",
    checkName: "",
    feedback: "",
    comment: "",
    checkId: "",
    systemResult: ""
  });
  const [claimData, setClaimData] = useState({
    feedback: "",
    comment: ""
  });
  const { version, claimNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [images, setImages] = useState({});
  const [questions, setQuestions] = useState([]);

  const getClaimsDetails = async () => {
    try {
      setLoading(true);
      const requesData = {
        version: version
      };
      const [error, response] = await Utils.parseResponse(
        getClaimDetails(claimNumber, requesData)
      );

      if (error || !response) {
        if (!error?.response?.data?.success);
        if (error?.response?.status === 500) {
          toast.error("Internal server error");
        } else {
          toast.error(error?.response?.data?.message);
        }
        navigate("/claim-list");
        return;
      }
      setData(response);
      setFeedbackData(response?.feedback);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const updateElementFeedback = (updatedFeedback) => {
    setFeedbackData((prev) => {
      if (!prev) {
        return { elementsFeedback: [updatedFeedback] };
      }
      if (!prev.elementsFeedback) {
        return {
          ...prev,
          elementsFeedback: [updatedFeedback]
        };
      }

      const replaceObjectIndex = prev.elementsFeedback.findIndex(
        (item) => item.elementName === updatedFeedback.elementName
      );

      if (replaceObjectIndex !== -1) {
        const updatedElementsFeedback = [...prev.elementsFeedback];
        updatedElementsFeedback[replaceObjectIndex] = {
          ...updatedElementsFeedback[replaceObjectIndex],
          ...updatedFeedback
        };
        return {
          ...prev,
          elementsFeedback: updatedElementsFeedback
        };
      }

      return {
        ...prev,
        elementsFeedback: [...prev.elementsFeedback, updatedFeedback]
      };
    });
  };

  const updateClaimFeedback = (updatedFeedback) => {
    setFeedbackData((prev) => {
      return {
        ...prev,
        ...updatedFeedback
      };
    });
  };

  const updateChecks = (updatedCheck) => {
    setFeedbackData((prev) => {
      if (!prev) {
        return { checks: [updatedCheck] };
      }

      if (!prev.checks) {
        return {
          ...prev,
          checks: [updatedCheck]
        };
      }

      const replaceCheckIndex = prev.checks.findIndex(
        (item) => item.checkName === updatedCheck.checkName
      );

      if (replaceCheckIndex !== -1) {
        const updatedChecks = [...prev.checks];
        updatedChecks[replaceCheckIndex] = {
          ...updatedChecks[replaceCheckIndex],
          ...updatedCheck
        };
        return {
          ...prev,
          checks: updatedChecks
        };
      }
      return {
        ...prev,
        checks: [...prev.checks, updatedCheck]
      };
    });
  };

  const handleFeedback = async () => {
    try {
      let requesData = {
        claimId: data?.claimId,
        claimNumber: claimNumber,
        feedback: claimData.feedback ? claimData.feedback : undefined,
        comment: claimData.comment ? claimData.comment : undefined,
        createdBy: user?.userId,
        version: version
      };

      const isElementDataEmpty = Object.values(elementData).every(
        (value) => value === "" || value == null
      );
      const isCheckDataEmpty = Object.values(checkData).every(
        (value) => value === "" || value == null
      );

      if (!isElementDataEmpty) {
        requesData.elementsFeedback = [elementData];
      }
      if (!isCheckDataEmpty) {
        requesData.checks = [checkData];
      }
      const [error, response] = await Utils.parseResponse(
        postClaimFeedback(requesData, data?.claimId)
      );

      if (error || !response) {
        if (error?.response?.status === 500) {
          toast.error("Internal server error");
        } else {
          toast.error(error?.response?.data?.message);
        }
        return;
      }
      if (elementData?.feedback) {
        updateElementFeedback(elementData);
      } else if (checkData?.feedback) {
        updateChecks(checkData);
      } else if (claimData?.feedback) {
        updateClaimFeedback(claimData);
      }
      toast.success("Feedback added successfully");
      setElementData((prev) => ({
        ...prev,
        elementName: "",
        feedback: "",
        comment: "",
        claimData: "",
        systemResult: ""
      }));
      setClaimData((prev) => ({
        ...prev,
        feedback: "",
        comment: ""
      }));
      setCheckData((prev) => ({
        ...prev,
        componentName: "",
        componentId: "",
        checkName: "",
        feedback: "",
        comment: "",
        checkId: "",
        systemResult: ""
      }));
      setFeedbackId(response?._id);
      setOpenReasonPopup(null);
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdateFeedback = async () => {
    try {
      let requesData = {
        claimId: data?.claimId,
        claimNumber: claimNumber,
        feedback: claimData.feedback ? claimData.feedback : undefined,
        comment: claimData.comment ? claimData.comment : undefined,
        createdBy: user?.userId,
        version: version
      };
      const isElementDataEmpty = Object.values(elementData).every(
        (value) => value === "" || value === null
      );

      const isCheckDataEmpty = Object.values(checkData).every(
        (value) => value === "" || value == null
      );
      if (!isElementDataEmpty) {
        requesData.elementsFeedback = [elementData];
      }
      if (!isCheckDataEmpty) {
        requesData.checks = [checkData];
      }
      const [error, response] = await Utils.parseResponse(
        updateFeedback(requesData, data?.feedback?._id || feedbackId)
      );

      if (error || !response) {
        if (error?.response?.status === 500) {
          toast.error("Internal server error");
        } else {
          toast.error(error?.response?.data?.message);
        }
        return;
      }
      if (elementData?.feedback) {
        updateElementFeedback(elementData);
      } else if (checkData?.feedback) {
        updateChecks(checkData);
      } else if (claimData?.feedback) {
        updateClaimFeedback(claimData);
      }
      toast.success("Feedback updated successfully");
      setElementData((prev) => ({
        ...prev,
        elementName: "",
        feedback: "",
        comment: "",
        claimData: "",
        systemResult: ""
      }));
      setClaimData((prev) => ({
        ...prev,
        feedback: "",
        comment: ""
      }));
      setCheckData((prev) => ({
        ...prev,
        componentName: "",
        componentId: "",
        checkName: "",
        feedback: "",
        comment: "",
        checkId: "",
        systemResult: ""
      }));
      setOpenReasonPopup(null);
    } catch (e) {
      console.error(e);
    }
  };

  const getInspectionImages = async () => {
    try {
      const [error, response] = await Utils.parseResponse(
        getImages(claimNumber)
      );

      if (error || !response) {
        if (error?.response?.status === 500) {
          toast.error("Internal server error");
        } else {
          toast.error(error?.response?.data?.message);
        }
        return;
      }
      setImages(response);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getInspectionImages();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getClaimsDetails();
    //eslint-disable-next-line
  }, []);

  const mappedData = Object.keys(images).flatMap((key) =>
    images[key].length > 0
      ? images[key].map((image) => ({
          image: image?.signedObjectUrl,
          type: key,
          componentName: image?.componentName || key,
          componentCode: image?.componentCode
        }))
      : [{ image: null, type: key, componentName: key }]
  );
  const handleBackNavigation = () => {
    if (location.key === "default") {
      navigate("/claim-list");
    } else {
      navigate(-1);
    }
  };

  if (loading) {
    return (
      <div className="flex h-75vh items-center justify-center">
        <div className="loader "></div>
      </div>
    );
  }
  return (
    <div>
      <div className="flex gap-2">
        <img
          onClick={handleBackNavigation}
          src="/images/left-arrow.svg"
          alt=""
          className="cursor-pointer"
        />
        <span className="font-InterBold text-ft25-30 text-black-10">
          Claim Report
        </span>
      </div>
      <div className="flex w-full gap-4 pt-6">
        <div className="w-80per">
          <ClaimDetails
            imagesData={mappedData}
            setImagePopup={setImagePopup}
            elementData={elementData}
            handleFeedback={handleFeedback}
            handleUpdateFeedback={handleUpdateFeedback}
            openReasonPopup={openReasonPopup}
            setElementData={setElementData}
            setOpenReasonPopup={setOpenReasonPopup}
            data={data}
            setQuestions={setQuestions}
            questions={questions}
            setCheckData={setCheckData}
            checkData={checkData}
            setComponentType={setComponentType}
            componentType={componentType}
            feedbackData={feedbackData}
          />
        </div>
        <div className="w-20per">
          <RightPanel
            setImagePopup={setImagePopup}
            imagesData={mappedData}
            claimData={claimData}
            handleFeedback={handleFeedback}
            handleUpdateFeedback={handleUpdateFeedback}
            openReasonPopup={openReasonPopup}
            setClaimData={setClaimData}
            setOpenReasonPopup={setOpenReasonPopup}
            data={data}
            setComponentType={setComponentType}
            componentType={componentType}
            feedbackData={feedbackData}
          />
        </div>
      </div>
      {openImage.popup && (
        <ImageZoom
          openImage={openImage}
          setImagePopup={setImagePopup}
          data={questions}
        />
      )}
    </div>
  );
};

export default ClaimDetailsComponent;
